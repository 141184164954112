import { Container } from './styles';
import { useGlobalStore } from '../../../../../../infra/state/GlobalStore';
import locality from '../../../../../assets/icons/locality.svg';
import setting from '../../../../../assets/icons/setting.svg';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Badges = {
  BRONZE: 'https://i.ibb.co/TkzN26f/bronze.png',
  SILVER: 'https://i.ibb.co/vh9KkFx/silver.png',
  GOLD: 'https://i.ibb.co/7GSVLSh/gold.png',
};

export const HeaderMyAccount: React.FC = () => {
  const [badge, setBadge] = useState<string>(Badges.BRONZE);
	const store = useGlobalStore();

  const userBalance = store.state.user?.wallet.balance;

  useEffect(() => {
		console.log(process.env.REACT_APP_GOLD_BADGE);
		console.log(process.env.REACT_APP_SILVER_BADGE);
    if (userBalance != undefined) {
      if (userBalance >= +process.env.REACT_APP_GOLD_BADGE!) return setBadge(Badges.GOLD);
      if (userBalance >= +process.env.REACT_APP_SILVER_BADGE!) return setBadge(Badges.SILVER);
      setBadge(Badges.BRONZE);
    }
  }, [userBalance]);
  console.log(userBalance);
  const users = useGlobalStore();
  const { user } = users.state;
  const name = user?.name;
  const nameArray = name?.split(' ');
  let firstName = '';
  let lastName = '';

  if (nameArray && nameArray.length === 1) {
    firstName = nameArray[0];
  } else if (nameArray && nameArray.length > 1) {
    firstName = nameArray[0];
    lastName = nameArray[nameArray.length - 1];
  }

  return (
    <Container.head>
      <Container.text>
        <p>Seja bem-vindo!</p>
        <h2 data-test="h2-firstName-lastName">{`${firstName} ${lastName}`}</h2>
      </Container.text>
      <Container.text>
        <Container.headerRight>
          {<img style={{ alignSelf: "center", width: '40px' }} src={badge} />}
          <img style={{ width: '75px' }} src={`/avatar/${user?.avatar}.svg`} alt="avatar" />
          <Container.textUser>
            <h4 data-test="h4-username">{user?.username}</h4>
            <div>
              <img src={locality} alt="localização" />
              <p data-test="p-city">{user?.address.city},</p>
              <p data-test="p-state">{user?.address.state}</p>
            </div>
          </Container.textUser>
          <Link to="/profile" className="linkSettings">
            <img src={setting} alt="setting" />
          </Link>
        </Container.headerRight>
      </Container.text>
    </Container.head>
  );
};
